.landing-img {
  background-image: url("../Images/TOP_pc.png");
  height: 70vw;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 110%;
  // background-position-x: -5rem;
}
.navbar {
  position: fixed;
  top: 3rem;
  left: 4rem;
  right: 4rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 50;
  img {
    position: absolute;
    width: 8rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .menu-btn,
  .cart-btn {
    position: relative;
    cursor: pointer;
    font-size: 1.7rem;
    // font-weight: 900;
    letter-spacing: 0px;
    font-family: "Oswald", sans-serif;
    transition: 0.2s;
    box-sizing: border-box;
    padding: 0 1.5rem;
    // padding-top: 0.3rem;
    text-align: center;
    border-radius: 2rem;
    font-weight: bold;
    // text-shadow: 1px 0 #ffffff90, 0 1px #ffffff90, 1px 1px #ffffff90,
    //   -1px -1px #ffffff90;
    &:hover {
      background-color: #000;
      text-shadow: none;
      color: #fff;
    }
  }
  .cart-btn {
    border: 0.1rem solid #000;
    span {
      display: grid;
      place-items: center;
      box-sizing: border-box;
      padding-top: 2px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      line-height: 0;
      font-size: 1.2rem;
      position: absolute;
      top: -1rem;
      right: -1rem;
      background-color: #fff;
    }
  }
}
.menu-overlay {
  animation: fadeIn 0.2s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: grid;
  place-items: center;
  backdrop-filter: blur(3px);
  z-index: 10;
  ul {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 1.5rem;
    margin-top: 3rem;
    li {
      opacity: 0;
      animation: popup 0.5s forwards;
      display: inline-flex;
      color: #fff;
      cursor: pointer;
      padding: 1rem 0;
      p {
        writing-mode: vertical-rl;
        text-orientation: mixed;
        color: #fff;
        font-weight: 500;
        font-size: 1.8rem;
      }
      span {
        writing-mode: vertical-lr;
        // font-family: "Noto Serif JP";
        line-height: 0;
      }
      // &:hover {
      //   background-color: #fff;
      //   // color: #000;
      //   p {
      //     color: #000;
      //     font-weight: bold;
      //   }
      // }
    }
  }
}
.brand {
  display: block;
  margin: 0.5rem 0;
}
.brand-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // height: 50vw;
  grid-column: 2;
  // overflow: hidden;
  // img {
  //   object-fit: contain;
  // }
  & > div {
    box-sizing: border-box;
    // overflow: hidden;
  }
}

.cart-rule {
  font-size: 1.2rem;
}
.cart-rule1 {
  font-size: 0.8rem;
}
// .carousel {
//   // display: inline;
//   position: relative;
//   // display: grid;
//   place-items: center;
// }
.responsive {
  height: 100%;
  width: 100%;
}

// .Carousel-indicators-2 {
//   margin-top: -35px;
// }
// .Carousel-button-7 {
//   background-color: none;
//   color: black;
// }
// .MuiSvgIcon-root {
//   font-size: 4.5rem;
// }

.br-carousel {
  position: relative;
  display: grid;
  // background-color: #a2f39d;
  img {
    width: 100%;
    // height: 100%;
    margin-top: 0;
  }
  .c-soon {
    display: grid;
    place-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: "Oswald", sans-serif;
    font-size: 4rem;
    color: #fff;
    // text-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
}
.br-name {
  position: relative;
  display: grid;
  place-items: center;
  div {
    // margin: 1rem;

    & > div {
      display: flex;
      flex-direction: column;
    }
    span {
      display: inline-block;
      text-align: start;
      color: #000;
      font-weight: bold;
      line-height: 1;
      font-family: "Noto Serif JP";
    }
    .sel {
      color: #fff;
    }
  }
  p {
    position: absolute;
    right: 1rem;
    top: 5rem;
    font-weight: bold;
    color: #000;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 1.4rem;
    font-family: "Oswald", sans-serif;
    letter-spacing: 0;
    & > span {
      display: inline-block;
      margin-top: 1rem;
    }
  }
}
.br-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0 4rem 5rem;
  &-head {
    margin-bottom: 1rem;
  }
  p {
    color: #000;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
    line-height: 1.3;
    font-size: 1.3rem;
    // margin-right: 10rem;
  }
  h2 {
    margin: 1rem 0;
    // margin-right: 6rem;
  }
  &-price {
    font-size: 3rem;
    font-family: "Oswald", sans-serif;
    margin: 3rem 0 1.7rem 0;
  }
  &-input {
    display: grid;
    grid-template-columns: auto auto 1fr;
    gap: 1.5rem;
    padding-right: 5%;
    & > div {
      p {
        width: 100%;
        text-align: center;
        margin: 10px 0;
        padding: 0;
        font-family: "Oswald", sans-serif;
        letter-spacing: 0;
      }
    }
    button {
      border-radius: 4rem;
      height: 6rem;
      align-self: flex-end;
      border: none;
      background-color: #000;
      font-size: 1.5rem;
      color: #fff;
      font-family: "Oswald", sans-serif;
      cursor: pointer;
      &:disabled {
        background-color: #aaa;
        cursor: default;
      }
    }
  }
}
.br-image {
  // display: grid;
  // place-items: center;
  .custom-class {
    img {
      width: 100%;
      height: 100%;
      position: relative;
      background-size: cover;
      background-position: center;
    }
  }
}

.br-image-soon {
  display: grid;
  place-items: center;

  img {
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
  }
}

// .rev {
//   // margin-left: 5rem;
// }

.con {
  h2 {
    font-size: 1.5rem;
    font-family: "Noto Serif JP";
  }

  h3 {
    font-size: 1.2rem;
    font-family: "Oswald", sans-serif;
  }
}

.con1 {
  h2 {
    font-size: 1.5rem;
    font-family: "Noto Serif JP";
  }

  h3 {
    font-size: 1.2rem;
    font-family: "Oswald", sans-serif;
  }
}
// #concept {
//   height: 70vw;
// }

// .content {
//   display: none;
// }

.content {
  display: inline;
  position: relative;
  display: grid;
  place-items: center;

  p {
    position: absolute;
    right: 1rem;
    top: 4vw;
    font-weight: bold;
    color: #000;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    font-size: 1.4rem;
    font-family: "Oswald", sans-serif;
    letter-spacing: 0;
    & > span {
      display: inline-block;
      margin-top: 1rem;
    }
  }
  .cont-img {
    width: 100%;
    // height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
  }
}

.br-details-head {
  font-size: 1.4rem;
}

.mobrev {
  display: none;
}

.illustration-mob {
  display: none;
}

.parallax {
  /* The image used */
  background-image: url("../Images/2.png");

  /* Set a specific height */
  // min-height: 50vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 115%;
  background-repeat: no-repeat;
  background-size: 53vw;
  background-color: #dddac7;
  float: right;
}

.parallax1 {
  /* The image used */
  background-image: url("../Images/3.png");

  /* Set a specific height */
  // min-height: 50vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 120%;
  background-repeat: no-repeat;
  background-size: 56vw;
  background-color: #b4d3ce;
  float: right;
}

.parallax4 {
  /* The image used */
  background-image: url("../Images/18.png");

  /* Set a specific height */
  // min-height: 50vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 120%;
  background-repeat: no-repeat;
  background-size: 65vw;
  background-color: #c8ccbe;
  // float: right;
}

.parallax3 {
  /* The image used */
  background-image: url("../Images/crop3.jpg");

  /* Set a specific height */
  // min-height: 50vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: 50vw;

  background-color: #c9c1d0;
  // float: right;
}

@media only screen and (max-width: 1679px) {
  .br-details {
    h2,
    p {
      // font-size: 1.3rem;
      margin: 1rem 0 0 0;
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1550px) {
  .br-details {
    h2,
    p {
      font-size: 1.2rem;
      margin: 1rem 0 0 0;
      margin-right: 0;
    }
  }
}

// @media only screen and (max-width: 1432px) {
//   .landing-img {
//     // background-size: 150%;
//     // background-position-x: -5rem;
//   }
// }

@media only screen and (max-width: 1344px) {
  .br-details {
    padding: 4rem 0 4rem 3rem;
    h2,
    p {
      // font-size: 1.5vh;
      // font-size: 1rem;
      margin: 1rem 0 0 0;
      margin-right: 0;
    }
  }

  // .br-details-price {
  //   // margin: 0;
  // }
}

@media only screen and (max-width: 1238px) {
  .br-details {
    padding: 4rem 0 4rem 2rem;
    h2,
    p {
      // font-size: 1.5vh;
      font-size: 1rem;
      margin: 1rem 0 0 0;
      margin-right: 0;
    }
  }

  // .br-details-price {
  //   // margin: 0;
  // }
}

@media only screen and (min-width: 1024px) {
  .menu-overlay .a {
    margin: 12rem 0 0;
  }
}

@media only screen and (max-width: 1024px) {
  .menu-overlay {
    .a {
      margin: 0;
      margin-top: 10rem;
      li {
        p {
          font-size: 1.5rem;
        }
      }
    }
    .b {
      margin: 0;
      margin-top: 2.5rem;
      li {
        p {
          font-size: 1.5rem;
        }
      }
    }
  }
  // .brand-row {
  //   grid-template-columns: 1fr;
  //   height: auto;
  //   & > div {
  //     height: 70vh;
  //   }

  //   // .carousel {
  //   //   height:
  //   // }
  // }

  // .ctt {
  //   height: 10vh;
  // }

  // .br-details {
  //   // z-index: 2;
  //   h2,
  //   p {
  //     font-size: 1.2rem;
  //   }
  //   .br-details-price {
  //     margin: 0;
  //   }
  // }

  .br-details {
    padding: 4rem 0 4rem 2rem;
    h2,
    p {
      // font-size: 1.5vh;
      font-size: 0.8rem;
      margin: 1rem 0 0 0;
      margin-right: 0;
    }
  }

  .br-carousel {
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .br-name {
    .rtl {
      top: 1rem;
    }
  }

  .navbar {
    top: 2rem;
    left: 10px;
    right: 10px;
    img {
      width: 8rem;
    }
  }

  .landing-img {
    // height: 120vh;
    background-size: cover;
    // background-position-x: -6rem;
    // overflow: hidden;
  }

  .rev {
    margin-left: 3rem;
    margin-bottom: 3rem;
  }
  .con1 {
    h2 {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }

  .content {
    p {
      top: 4vw;
    }
  }
}

// #concept {
//   height: 100vw;
// }
@media only screen and (max-width: 1024px) {
  .br-details {
    padding: 2rem 0 2rem 2rem;
    &-input {
      button {
        height: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 845px) {
  .br-details {
    h2,
    p {
      // font-size: 1.5vh;
      font-size: 0.6rem;
    }
  }

  .br-details-head {
    font-size: 1em;
  }

  // .content {
  //   .rtl {
  //     // font-size: 1em;
  //   }
  // }
}

// @media only screen and (max-width: 800px) {
//   .brand-row {
//     .ctt {
//       height: 0vh;
//     }
//   }
// }

@media only screen and (max-width: 720px) {
  .menu-overlay {
    ul {
      margin: 0;
      margin-top: 2.5rem;
      li {
        p {
          font-size: 1rem;
        }
        span {
          font-size: 0.7rem;
        }
      }
    }
  }

  .brand-row {
    grid-template-columns: 1fr;
    height: auto;
    & > div {
      height: 100vw;
    }

    .br-details {
      height: auto;
      &-price {
        margin: 1.5rem 0 0.5rem;
      }
    }
    // .carousel {
    //   height:
    // }
  }
  // .br-name {
  //   div {
  //     span {
  //       font-size: 8rem;
  //     }
  //   }
  //   p {
  //     font-size: 1rem;
  //     right: 0;
  //   }
  // }

  .br-name {
    display: none;
  }

  .br-carousel {
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  .br-details {
    font-size: 0.9rem;
    padding: 1rem;
    z-index: 2;
    h2 {
      div {
        font-size: 1.2rem;
      }
    }
    p {
      div {
        font-size: 1.2rem;
      }
    }

    &-input {
      & > div {
        input {
          width: 3rem;
          height: 3rem;
        }
      }
      button {
        height: 4rem;
      }
    }
    &-price {
      font-size: 2rem;
    }
    h2 {
      // margin: 1rem 0;
      font-size: 1.2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
  #contact {
    #img-section {
      img {
        width: 1rem;
      }
    }
  }
  .navbar {
    top: 0.5rem;
    left: 10px;
    right: 10px;
    img {
      width: 5rem;
    }

    .menu-btn,
    .cart-btn {
      font-size: 1rem;
    }
  }

  .landing-img {
    // height: 45vh;
    background-image: url("../Images/TOP_sp.png");
    height: 100vw;
    background-size: cover;
    // background-position-x: -6rem;
    // overflow: hidden;
  }

  .cart {
    width: 390px;
  }

  #ct {
    font-size: 1em;
  }

  .con {
    h2 {
      font-size: 0.8rem;
    }
    h3 {
      font-size: 0.8rem;
    }
  }
  .con1 {
    h2 {
      font-size: 0.7rem;
    }
    h3 {
      font-size: 0.7rem;
    }
  }

  .footerimg {
    margin-top: -10px;
    object-fit: "contain";
    background-color: "#89abad";
  }

  .rev {
    margin-left: 3rem;
    margin-bottom: 3rem;
  }

  .content {
    display: inline;
    position: relative;
    display: grid;
    // place-items: center;
    div {
      // margin: 1rem;

      & > div {
        display: flex;
        flex-direction: column;
      }
      span {
        display: inline-block;
        text-align: start;
        color: #000;
        font-weight: bold;
        line-height: 1;
        font-family: "Noto Serif JP";
      }
      .sel {
        color: #fff;
      }
    }
    p {
      position: absolute;
      right: 1rem;
      top: 8vw;
      font-weight: bold;
      line-height: 0em;
      color: #000;
      writing-mode: vertical-rl;
      text-orientation: mixed;
      font-size: 1rem;
      font-family: "Oswald", sans-serif;
      letter-spacing: 0;
      & > span {
        display: inline-block;
        margin-top: 1rem;
      }
    }
    .cont-img {
      width: 100%;
      height: 100%;
      position: relative;
      background-size: cover;
      background-position: center;
    }
  }

  .responsive {
    width: 100%;
  }

  .desk {
    display: none;
  }
  .mobrev {
    display: inline;
  }
  // .parallax,
  // .parallax1 {
  //   background-size: 120vw;
  //   background-position: left;
  // }
  // .parallax4 {
  //   background-size: 120vw;
  //   background-position: center;
  // }
  // .parallax3 {
  //   background-size: 100vw;
  //   background-position: center;
  // }
  .parallax,
  .parallax1,
  .parallax3,
  .parallax4 {
    background-attachment: unset;
    width: 100%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  .parallax {
    background-image: url("../Images/sp_oda.jpg");
  }
  .parallax1 {
    background-image: url("../Images/sp_akechi.jpg");
  }
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .parallax,
  .parallax1,
  .parallax3,
  .parallax4 {
    background-attachment: scroll;
  }
  .parallax {
    background-image: url("../Images/sp_oda.jpg");
    // background-size: 50vw;
  }
  .parallax1 {
    background-image: url("../Images/sp_akechi.jpg");
    // background-size: 50vw;
  }
}

@media only screen and (max-width: 600px) {
  .br-details {
    h2 {
      div {
        font-size: 1rem;
      }
    }
    p {
      div {
        font-size: 1rem;
      }
    }
    p {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 507px) {
  // .responsive {
  //   width: auto;
  // }

  .brand-row {
    grid-template-columns: 1fr;
    height: auto;
    // & > div {
    //   // height: 100vw;
    // }

    // .br-image {
    //   height: 50vh;
    // }
    // .br-details {
    //   height: 60vh;
    // }
    // .content {
    //   height: 70vh;
    // }
    // .carousel {
    //   height:
    // }
  }
}

@media only screen and (max-width: 520px) {
  .br-details {
    h2 {
      div {
        font-size: 0.9rem;
      }
    }
    p {
      div {
        font-size: 0.9rem;
      }
    }
    p {
      font-size: 0.9rem;
    }
  }
}

@media only screen and (max-width: 430px) {
  .br-details {
    h2 {
      div {
        font-size: 0.74rem;
      }
    }
    p {
      div {
        font-size: 0.74rem;
      }
    }
    p {
      font-size: 0.74rem;
    }
  }

  .cart-rule {
    font-size: 1.2rem;
  }

  .cart-rule1 {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 373px) {
  .br-details {
    h2 {
      div {
        font-size: 0.64rem;
      }
    }
    p {
      div {
        font-size: 0.64rem;
      }
    }
  }
  // .content {
  //   .rtl {
  //     // font-size: 0.8rem;
  //   }
  // }
}

@media only screen and (max-width: 777px) and (min-width: 500px) {
  .con {
    h2 {
      font-size: 1rem;
    }
    h3 {
      font-size: 1rem;
    }
  }
  .con1 {
    h2 {
      font-size: 1rem;
      font-weight: 700;
    }
    h3 {
      font-size: 1rem;
    }
  }
}
