/* //// Global Styles \\\\ */
$black: rgba(0, 0, 0, 1);
$white: rgba(255, 255, 255, 1);
$darkGray: rgba(0, 0, 0, 0.75);
$transOrange: rgba(245, 132, 20, 0.75);
$ultraTransOrange: rgba(245, 132, 20, 0.25);
$opaqueOrange: rgba(245, 132, 20, 1);

@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    text-overflow: ellipsis;
    color: $color;
  }
  &:-moz-placeholder {
    text-overflow: ellipsis;
    color: $color;
  }
  &::-moz-placeholder {
    text-overflow: ellipsis;
    color: $color;
  }
  &:-ms-input-placeholder {
    text-overflow: ellipsis;
    color: $color;
  }
}

@mixin box-shadow($color) {
  -webkit-box-shadow: 0 0 5px $color;
  -moz-box-shadow: 0 0 5px $color;
  box-shadow: 0 0 5px $color;
}

@mixin fieldFocus() {
  &:focus {
    color: $white;
    background-color: rgb(177, 221, 223);
    // border: 2px solid $white;
    // @include box-shadow($white);
    // @include placeholder($white);
  }
}

.pageWrapper{
  animation: slide 0.5s;
  background-color: white;
  position: fixed;
  height: 120vh;
  width: 390px;
  max-width:100%;
  max-height: 100%;
  z-index:100;
  right:0;
  transition: 0.2s;
  .cart{
    // animation: slide 0.5s;
    max-height: 100%;
    background-color: #F6F6F6;
    overflow-y: auto;
    position: fixed;
    height: 120vh;
    width: 390px;
    max-width:100%;
    z-index:100;
    right:0;
    transition: 0.2s;
    .close-icon{
      position:absolute;
      font-size:2rem;
      right:1.5rem;
      top:1.5rem;
      cursor: pointer;
    }
    h2 {
      margin: 1.5rem 2rem;
      font-family: "Oswald", sans-serif;
      font-size: 2rem;
    }
    .cart-wrapper{
      // position: relative;
      // height: 50vh;
      // overflow-y: auto;
      // margin-bottom:15px;
      align-items: center;
      text-align: center;
      justify-content: center;
      .formWrap{
        width: 75%;
        max-width: 500px;
        height: 100%;
        margin: 0 auto;
        padding: 1rem 0;
        .regionTitle{
          font-weight:bold;
          color:#2c3e50;
          margin-bottom:15px;
        }
      }
      .listPhotos {
        position: relative;
        border:solid;
        border-radius:4px;
        margin-left:15px;
        margin-right:15px;
        border: none;
        background-color:white;
        // border-color:#E7E7E7;
        box-shadow: rgba(35, 35, 35, .18) 0px 2px 8px;    
        border-width:2px;
        margin-bottom:10px;
        // overflow: hidden;
        display:flex;
        flex-direction: row;
        // display: grid;
        // grid-template-columns: 6rem 1fr;
        // column-gap: 2rem;
        // padding: 1rem 3rem;
        transition: 0.2s;
        .imageWrapper{
          margin-left:10px;
          margin-top:13px;
          margin-bottom:25px;
        .c-size {
          display: grid;
          place-items: center;
          width: 2rem;
          height: 2rem;
          border-radius: 50%;
          position: absolute;
          background-color: #000;
          color: #fff;
          left: 0.2rem;
          top: 2px;
          font-family: "Oswald", sans-serif;
          font-weight: bold;
          font-size: 1.2rem;
          line-height: 0;
          }
        }
        .detailsWrapper{
          // align-items: center;
          // text-align: center;
          // justify-content: center;
          .detailName{
            text-align: center;
            margin-left: 25px;
            margin-top: 15px;
            font-size: 1.2rem;
            color: #000;
            margin-bottom: 7px;
          }
          .price {
            font-family: "Oswald", sans-serif;
            background-color: #000;
            color: #fff;
            padding: 7px;
            // margin-right: 1rem;
            // border-radius: 2.5rem;
            margin-top:10px;
            border-radius:10px;
            // margin-left: 15px;
          }
          .qtyWrapper{
            // background-color: #000;
            // color: #fff;
            // padding: 4px 10px;
            // // margin-right: 1rem;
            // // border-radius: 2.5rem;
            // border-radius:25px;
            margin-left: 15px;
            margin-top:13px;
          }
          .deleteWrapper{
            margin-top: 18px;
            margin-left:75px;
            .del-icon {
              font-size: 3rem;
              &:hover{
                color:red;
              }
          }
        }
      }
      // &:hover {
      //   background-color: #f1f1f1;
      //   img {
      //     box-shadow: 0 10px 2rem rgba(0, 0, 0, 0.1);
      //   }
      // }
      }
    } 
  }
}

.cart-back{
  animation: fadeIn 0.2s;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  z-index: 100;
}

.cart-bottom {
  position: relative;
  text-align: center;
  align-items: center;
  // bottom: 1rem;
  // right: 2rem;
  // left: 2rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  // align-items: flex-end;
  .c-t {
    // display: grid;
    // grid-template-columns: 1fr;
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    text-align: center;
    align-items: center;
    font-size:1.5rem;
    span {
      font-family: "Oswald", sans-serif;
      font-size: 2rem;
      &:first-child {
        font-size: 0.9rem;
        font-weight: bold;
      }
    }
  }
  .c-f {
    border: 0.1rem solid #000;
    border-radius: 7px;
    padding: 2px 2rem 3px 2rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    background: transparent;
    font-family: "Oswald", sans-serif;
    font-size: 1.5rem;
    transition: 0.2s;
    margin-top:18px;
    margin-bottom:145px;
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.c-dd1 {
  position: absolute;
  // display: inline-grid;
  // display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index:1;
  cursor: pointer;
  &-display {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #57AE99;
    color:white;
    border-radius:5px;
    // cursor: pointer;
    border:none;
    outline:none;
    padding: 2px 10px 2px 10px;
    margin-top:1.2px;
    &:hover {
      box-shadow: 0 0 0 1px #57AE99;
    }
  }
  ul {
    // position: absolute;
    background-color: white;
    box-shadow: rgba(28, 28, 28, 0.12) 0px 2px 8px;    
    z-index: 10;
    margin-top:3px;
    border-radius:3px;
    padding:3px;
    color:57AE99;
    margin-left:5px;
    align-items: center;
    text-align: center;
    li {
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }
}

Form {
  width: 100%;
  height: 100%;
}

.form-group {
  margin-top: 10px;
  // height: 9rem;
  margin-bottom: 20px;
  display: flex;
  // width: 100%;
  margin-left: 15px;
  flex-direction: column;
  .formsubGroup{
    // margin-left: -220px;
    margin-bottom: 10px;
    .titleWrapper{
      align-content: flex-start;
      font-weight: bold;
      color: #2c3e50;
      font-size:1.2rem;
    }
  }
  .PhoneInputCountry{
    border:solid;
    border-radius:5px;
    border-width:1.8px;
    border-color:#E7E7E7;
    padding: 5px;
    outline:none;
  }
  .PhoneInputInput{
    border:solid;
    border-radius:5px;
    border-width:1.8px;
    border-color:#E7E7E7;
    padding: 5px;
    outline:none;
    width:225px;
    @include placeholder(rgb(212, 212, 212));
  }
  Input {
    &[type="text"],
    &[type="number"],
    &[type="email"],
    &[type="password"] {
      width: 300px;
      height: 1.5rem;
      // margin: 0 0.25rem 0.5rem;,
      padding-left: 0.5rem;
      // background-color: rgb(255, 255, 255);
      border:solid;
      border-width:1.9px;
      border-color: #E7E7E7;    
      outline: none;
      transition-property: background-color, border, box-shadow;
      transition-duration: 0.5s;
      border-radius: 5px;
      @include placeholder(rgb(212, 212, 212));
    }
    &[type="submit"] {
      width: 75%;
      height: 2.5rem;
      line-height: 2.5rem;
      margin: 1rem 0 0 12.5%;
  
      border: 2px solid white;
      border-radius: 0.25rem;
  
      color: $white;
      text-transform: uppercase;
      background-color: $opaqueOrange;
      transition: background-color 0.5s;
      &:hover {
        color: $white;
        border: 2px solid $white;
        background-color: $black;
      }
      &:focus {
        background-color: $opaqueOrange;
        outline: none;
      }
    }
  }
}

.formWrapper{
  height: 100%;
  padding: 1rem 0;
  align-items:center;
  text-align: center;
  margin-left: 15px;
  margin-right:35px;
  display: flex;
  flex-direction: column;
}


.phone {
  width: 96%;
  height: 1.5rem;
  margin: 0 0.25rem 0.5rem;
  padding-left: 0.5rem;
  background-color: rgb(255, 255, 255);

  outline: none;
  transition-property: background-color, border, box-shadow;
  transition-duration: 0.5s;

  @include placeholder(rgb(212, 212, 212));
}


  // @include fieldFocus();
  // &:focus {
  //   color: $black;
  //   background-color: rgb(177, 221, 223);
  //   border: 2px solid $white;
  //   @include box-shadow($white);
  //   @include placeholder($white);
  // }



.sub {
  width: 75%;
  height: 2.5rem;
  // line-height: 2.5rem;
  // margin: 0.7rem 0 0 12.5%;
  margin:0 0 0 2.3rem;
  border: 2px solid white;
  border-radius: 0.25rem;

  color: #000;
  text-transform: uppercase;
  background-color: rgb(119, 153, 106);
  // align-items: center;
  // text-align: center;
  // align-content: center;
  transition: background-color 0.5s;
  &:hover {
    color: $white;
    border: 2px solid $white;
    background-color: rgb(141, 168, 31);
    // border-radius: 0.5rem;
  }
  &:focus {
    background-color: $opaqueOrange;
    outline: none;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c3e50;
  background-image: none;
  flex: 1;
  padding: 0 0.9em;
  color: #fff;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}
.select{
  position: relative;
  display: flex;
  width: 270px;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: 0.25em;
  margin-top:7px;
}
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}

.c-dd {
  position: relative;
  &-display {
    display: grid;
    place-items: center;
    width: 6rem;
    height: 6rem;
    background-color: #fff;
    box-shadow: 0 0 0 3px #000;
    cursor: pointer;
    box-sizing: border-box;
    padding-top: 10px;
    span {
      font-family: "Oswald", sans-serif;
      font-size: 4rem;
      line-height: 1;
    }
    &:hover {
      box-shadow: 0 0 0 1px #000;
    }
  }
  ul {
    position: absolute;
    top: 100%;
    background-color: #fff;
    min-width: 100%;
    box-shadow: 0 0 0 1px rgba($color: #000000, $alpha: 0.5);
    z-index: 10;
    li {
      padding: 5px 1rem;
      cursor: pointer;
      &:hover {
        background-color: #eee;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .c-dd {
    &-display {
      width: 4rem;
      height: 4rem;
      span {
        font-family: "Oswald", sans-serif;
        font-size: 2rem;
        line-height: 1;
      }
    }
  }
}

