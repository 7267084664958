html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  font-size: 100%;
  background: #fff;
  width: 100vw;
  overflow-x: hidden;
}

body a {
  text-decoration: none;
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -ms-transition: 0.2s all;
}

body img {
  max-width: 100%;
}

a:hover {
  text-decoration: none;
}

input[type="button"],
input[type="submit"],
input[type="text"],
input[type="email"],
input[type="search"] {
  transition: 0.2s all;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -o-transition: 0.2s all;
  -ms-transition: 0.2s all;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  letter-spacing: 0px;
}

p {
  margin: 0;
  line-height: 1.9em;
  color: #777;
  font-size: 15px;
  letter-spacing: 0px;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.font-20 {
  font-size: 20px;
}

/* .d-flex {
  display: flex;
} */

.d-flex {
  /* width: 200px; */
  /* border: 1px solid #2d2d2d; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}

.bold-text {
  font-weight: bold;
}

.font-black {
  color: black;
}

.text-upper {
  text-transform: uppercase;
}
.space-between {
  justify-content: space-between;
}
.flex-end {
  justify-content: flex-end;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.center {
  justify-content: center;
}
.text-center {
  text-align: center;
}
.centered {
  margin: 0 auto;
}
.rounded-border-2 {
  border: 2px solid black;
  border-radius: 500px;
}

.wd-50 {
  width: 50%;
}
.wd-60 {
  width: 60%;
}
.wd-20 {
  width: 20%;
}
.wd-40 {
  width: 40%;
}
.hidden {
  overflow: visible;
}

#clothlisting {
  margin-top: 1rem;
}

#clothlisting .cloth-img {
  margin: 0 auto;
  max-width: 100%;
  display: block;
}

#clothlisting .item01 {
  background-color: #ddb84d;
}

#clothlisting .items01 {
  background-color: #b37a4e;
}

#clothlisting h1 {
  font-size: 200px;
  line-height: 1;
  letter-spacing: 0rem;
  text-align: center;
  display: flex;
}
#clothlisting .content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5rem;
  margin: auto;
  height: 100%;
}
#clothlisting .rtl {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: black;
}
#clothlisting .rtl > span {
  font-size: 18px;
  font-weight: bold;
}
#clothlisting h4 {
  line-height: 1.6;
}

#clothlisting .items-head {
  color: black;
}

#clothlisting .add-to-cart {
  background-color: #000;
  color: #fff;
  text-align: center;
  width: 55%;
  height: 5rem;
  border: none;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 3rem;
  font-family: "Oswald", sans-serif;
}
#clothlisting .add-to-cart:focus {
  border: 0px !important;
  outline: 0;
}
#clothlisting .add-to-cart:hover {
  cursor: pointer;
}

#clothlisting input[type="text"] {
  padding: 1rem;
  font-size: 2rem;
  text-align: center;
  box-shadow: 0 0 0 3px #000;
}
#clothlisting input[type="text"]:focus {
  box-shadow: 0 0 0 4px #000;
  outline: 0;
}
#clothlisting .item02 {
  background: #9aa4e7;
}
#clothlisting .items02 {
  background: #32a0bee6;
}
#clothlisting .item03 {
  background: #7f3dbc9e;
}
#clothlisting .items03 {
  background: #f3a4b0;
}
#clothlisting .item04 {
  background: darkseagreen;
}
#clothlisting .items04 {
  background: #50c04e;
}
#concept {
  background: #c7bd62;
}
#concept h1 {
  line-height: 1.2rem;
}
#concept h5 {
  line-height: 2rem;
}
#concept h4 {
  line-height: 1.6rem;
}
#concept .heading {
  padding: 60px 0;
}
#concept .mg-adj {
  padding: 40px 0;
}
#concept .wd-70 {
  width: 70%;
}
.marginleft {
  left: auto;
}

#contact {
  background: #89abad;
}
#contact .text-center {
  line-height: 2rem;
}
#contact .heading {
  padding: 60px 0;
}
#contact .contact-btn {
  margin: 0 auto;
  display: block;
  background: black;
  border: 0;
  border-radius: 500px;
  color: white;
  padding: 20px;
  width: 200px;
  font-size: 20px;
  cursor: pointer;
}
#contact .contact-btn:focus {
  border: 0;
}
#contact > #img-section > img {
  width: 15rem;
  /* object-fit: contain; */
}
#contact > #img-section {
  display: flex;
  justify-content: center;
  flex: 1 0 auto;
  min-height: 130.96%;
}
.word-box {
  position: relative;
}
.word-box .rtl-wrap {
  position: absolute;
  right: 1rem;
  top: 3rem;
}
.item-input {
  display: inline-flex;
  align-items: center;
}
.item-input > div {
  position: relative;
}
.item-input > div p {
  position: absolute;
  top: -1.6rem;
  font-weight: bold;
}
.sm-view {
  display: none;
}

@media only screen and (max-width: 1024px) {
  #concept .wd-60 {
    width: 60%;
  }
  #concept > .d-flex {
    display: block;
  }
  .mobcenter {
    margin: auto;
    width: 50%;
  }

  .mobimg {
    text-align: center;
  }

  .sm-view {
    margin-top: 35px;
    display: block;
  }

  .sm-view h2 {
    text-align: center;
  }

  .sm-view h3 {
    text-align: center;
  }

  .lg-view {
    display: none;
  }

  #concept .heading {
    padding: 40px 0;
  }
}

@media only screen and (max-width: 777px) {
  #clothlisting > .d-flex {
    display: block !important;
  }
  #clothlisting .img-box {
    width: 100%;
  }
  #clothlisting > .d-flex > div {
    width: 10%;
  }
  #clothlisting h1 {
    font-size: 74px;
    letter-spacing: 0rem;
    line-height: 1.4;
  }

  #clothlisting .rtl > span {
    font-size: 16px;
  }
  #clothlisting .content-box {
    padding: 60px 20px;
    margin: 0;
  }
  #clothlisting > .d-flex > .wd-50 > .content-box > .d-flex > .wd-20 {
    width: 25%;
  }
  #clothlisting > .d-flex > .wd-50 > .content-box > .d-flex > .wd-20 > .wd-40 {
    width: 30%;
  }
}

@media only screen and (min-width: 1367px) {
  #clothlisting .add-to-cart {
    width: 300px;
  }
}
